export const getPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check for tablet devices
  if (/iPad/i.test(userAgent) || (navigator.userAgent.includes('Android') && !navigator.userAgent.includes('Mobile'))) {
    return 'TABLET_WEB';
  }

  // Check for mobile devices (excluding tablets)
  if (/android/i.test(userAgent) || /iPhone|iPod/i.test(userAgent)) {
    return 'MOBILE_WEB';
  }

  // Check for desktop operating systems
  if (/Windows NT/i.test(userAgent) || /Macintosh|Mac OS X/i.test(userAgent)) {
    // Further check if the screen width is small (which might be a tablet or mobile view)
    if (window.innerWidth <= 768) {
      return 'MOBILE_WEB';
    } else {
      return 'WEB';
    }
  }

  // Fallback for other devices
  if (window.innerWidth <= 768) {
    return 'MOBILE_WEB';
  }

  // Default to WEB for all other cases
  return 'WEB';
};



export const clientName = 'FAIRBETS';